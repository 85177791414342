import React from "react";
import { FaHeadset } from "react-icons/fa6";

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const handleRedirect = () => {
    window.location.href = "http://pf.kakao.com/_SVxdaxb";
  };
  return (
    <div
      // style={{
      //   backgroundImage: "linear-gradient(135deg, #ffd6dc 0%, #fff8e0 100%)",
      // }}
      className="w-screen h-screen bg-white flex justify-center"
    >
      <div className="w-screen min-h-screen bg-[#F7F9FA] h-full flex flex-col overflow-auto">
        {children}
        <button
          onClick={handleRedirect}
          className="flex justify-center items-center w-[72px] h-[72px] py-2 rounded-full fixed bottom-4 right-6 bg-[#0D2547] text-white"
        >
          <FaHeadset size={32} />
        </button>
      </div>
    </div>
  );
}

export default Layout;
