import { useLocation, useNavigate } from "react-router-dom";

function NavBar() {
  const navigate = useNavigate();
  const location = useLocation(); // 현재 위치를 가져옵니다.

  // 현재 경로가 주어진 경로와 일치하는지 여부를 판단하는 함수
  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {/* <button
        onClick={() => navigate("/")}
        className="w-full flex justify-start text-4xl font-bold"
      >
        Bluewhale LINK
      </button> */}
      <div className="flex items-center gap-6">
        <img
          className="w-16 h-16"
          src={process.env.PUBLIC_URL + "assets/img/remove_bluewhale.png"}
          alt="logo"
        />
        <span className="text-5xl font-semibold">청경학원</span>
      </div>
      <div className="flex gap-2 py-2 self-start">
        <button
          onClick={() => navigate("/")}
          className={`px-3 py-1 rounded-md font-semibold ${
            isActive("/")
              ? "bg-[#0D2547] text-white"
              : "bg-transparent text-black"
          } `}
        >
          홈페이지
        </button>
        <button
          onClick={() => navigate("/guide")}
          className={`px-3 py-1 rounded-md font-semibold ${
            isActive("/guide")
              ? "bg-[#0D2547] text-white"
              : "bg-transparent text-black"
          } `}
        >
          수업안내
        </button>
        {/* <button onClick={() => navigate("/notification")}>공지사항</button>
        <button onClick={() => navigate("/info")}>자료실</button>
        <button onClick={() => navigate("/story")}>청경 Story</button> */}
      </div>
    </div>
  );
}

export default NavBar;
