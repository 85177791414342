import Layout from "../components/layout/Layout";
import NavBar from "../components/layout/NavBar";

function Info() {
  return (
    <Layout>
      <NavBar />
      <div>자료실</div>
    </Layout>
  );
}

export default Info;
