import { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
// import NavBar from "../components/layout/NavBar";

function Main() {
  const [imageSrc, setImageSrc] = useState(
    process.env.PUBLIC_URL + "/assets/img/main.png"
  );

  useEffect(() => {
    const updateImageSrc = () => {
      if (window.innerWidth <= 768) {
        setImageSrc(process.env.PUBLIC_URL + "/assets/img/main3.png");
      } else {
        setImageSrc(process.env.PUBLIC_URL + "/assets/img/main.png");
      }
    };

    updateImageSrc(); 
    window.addEventListener("resize", updateImageSrc); 

    return () => window.removeEventListener("resize", updateImageSrc);
  }, []);

  return (
    <Layout>
      {/* <NavBar /> */}
      <div className="w-full flex justify-center">
        <img src={imageSrc} alt="main_img" className="w-full" />
      </div>
      {/* <Body /> */}
      <Footer />
    </Layout>
  );
}

export default Main;

function Footer() {
  return <></>;
}
