import { FaCalendarDay } from "react-icons/fa";
import { HiOutlineBookOpen } from "react-icons/hi";
import { RiGraduationCapFill } from "react-icons/ri";
import Layout from "../components/layout/Layout";
import NavBar from "../components/layout/NavBar";

function Guide() {
  return (
    <Layout>
      <NavBar />
      <div className="font-semibold text-3xl p-4">수업 안내</div>
      <Header />
      <Consulting />
    </Layout>
  );
}

export default Guide;

function Header() {
  return (
    <div className="w-full flex flex-col md:flex-row border-b-2 border-black pb-6">
      <div className=" p-4 bg-[#F1F1EF] w-full md:w-3/4 rounded-md flex">
        <div className=" mt-[4px] mr-[8px]">
          <RiGraduationCapFill size={20} />
        </div>
        <div className="w-full">
          <div className="border-b-[2px] border-black pb-2 w-full">
            Bluewhale
            <span className="text-[#347EA9]"> L I N K </span>
            Class System
          </div>
          <div className="p-2">
            <div className="my-2">
              <div>﹒모집 대상</div>
              <div className="pl-4">: 중학교 3학년 ~ 고등학교 3학년</div>
            </div>
            <div className="mb-2">
              <div>﹒교과 과목</div>
              <div className="pl-4">: 컨설팅, 국어, 영어</div>
            </div>
            {/* <div className="mb-2">
              <div>
                ﹒ 청경은 Consulting 전문 학원으로, Consulting 수강이 '필수'
                입니다.
              </div>
            </div> */}
            <div>
              <div>﹒수업 구성</div>
              <div className="pl-4">
                : 과목별 대면 2회 (총 160분) + 온라인 1회 (80분)
              </div>
              <div className="pl-4">[3회의 수업 모두 강의실에서 수강]</div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/4 border-[2px] rounded-md mt-2 md:mt-0 md:ml-4 border-[#F1F1EF] flex p-4">
        <div className="mr-[4px]">
          <FaCalendarDay size={20} />
        </div>
        <div className="flex flex-col gap-2 px-2">
          <div className="flex items-center">과목별 Curriculum</div>
          <button className="flex items-center">국어</button>
          <button className="flex items-center">영어</button>
        </div>
      </div>
    </div>
  );
}

function Consulting() {
  return (
    <>
      <div className="flex w-full mt-4 mb-2 bg-green-100 text-[18px]">
        <div className="w-full flex flex-col py-2 font-semibold">
          <div className="border-l-[4px] border-black w-full flex items-center px-2 h-full">
            Bluewhale
            <span className="text-[#347EA9] px-1"> L I N K </span>
            Consulting
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-[#F1F1EF] p-2 rounded-md">
        <span>
          청경은 Consulting을 통해 학생들이 가고자 하는 길 가장 가까이에서 함께
          걸어 가고자 합니다.
        </span>
        <span>
          학생들이 목표를 찾고 나아가는 길에 시행착오를 함께 겪으며 후회가 남지
          않도록 선생님, 그리고 친구의 역할을 하고자 합니다.
        </span>
        <span>
          Consulting 내용은 모두 기록이 남지만 상담 시, 내담자가 비밀유지 요청의
          경우 내용은 비밀 유지가 우선됩니다.
        </span>
      </div>
      <div className="flex p-2 rounded-md border mt-2">
        <div>
          <div className="flex mt-[2px] pr-2 items-center">
            <HiOutlineBookOpen size={20} />
          </div>
        </div>
        <div className="flex flex-col gap-2 ">
          <div className="w-full flex items-center pr-2 h-full font-semibold">
            Bluewhale
            <span className="text-[#347EA9] px-1"> L I N K </span>
            Consulting
          </div>
          <span>﹒ Tailoring System(학업 습관 분석)</span>
          <span>﹒ 멘탈 및 학습 Coaching</span>
          <span>﹒ 동기 부여(목표 설정)</span>
          <span>﹒ 생활기록부 관리</span>
          <span>﹒ 대학 입시 설계</span>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex w-full mt-4 mb-2 bg-[#E7F3F8]">
          <div className="w-full flex flex-col py-2 font-semibold text-[18px]">
            <div className="border-l-[4px] border-black w-full flex flex-col md:flex-row items-center px-2 h-full">
              <span className="md:w-auto w-full">
                왜? Bluewhale{" "}
                <span className="text-[#347EA9] px-1 md:w-auto w-full">
                  L I N K
                </span>
                는
              </span>

              <span className="md:w-auto w-full">
                강의실에서 온라인 수업을 수강하나요?
              </span>
            </div>
          </div>
        </div>
        <span className="mb-2">
          ﹒ 성공적인{" "}
          <span className="underline font-semibold">‘대학 입시’</span>를 목표로
          하는 청경에서는 내신과 수능을 모두 준비하기 위해 노력합니다.
        </span>
        <span>
          ﹒ 변화하는 대학 입시에서 수능의 중요도는 계속해서 높아지고 있습니다.
        </span>
        <span className="pl-5 mb-2">
          <span className="underline font-semibold">‘학생부 종합 전형’</span>
          에서의 생활기록부 완성도 기준은 계속해서 높아지고 있으며{" "}
          <span className="underline font-semibold">‘학생부 교과 전형’</span>
          에서의 수능 최저 등급 충족율은 계속해서 낮아지고 있습니다.
        </span>
        <span>
          ﹒ 수능 최저 등급의 충족률이 높지 않아, 수능 최저 충족 여부가 대학
          합격에 큰 영향을 끼치고 있습니다.
        </span>
        <span className="pl-5 font-semibold">
          수능 최저 기준 제시 여부에 따라 합격 평균 등급의 차이도 크게 납니다.
        </span>
        <span className="pl-5">
          수능의 중요도는 계속해서 높아지고 있으며, 앞으로의 대학입시에서도
          계속해서 중요해질 것으로 예측됩니다.
        </span>
        <span className="pl-5 underline font-semibold">
          온라인 강의의 목적은 ‘수능’에 대한 대비입니다.
        </span>
      </div>
      <div className="flex flex-col mb-24">
        <div className="flex w-full mt-4 mb-2 bg-[#E7F3F8]">
          <div className="w-full flex flex-col py-2 font-semibold">
            <div className="border-l-[4px] border-black w-full flex items-center px-2 h-full text-[18px]">
              온라인 수업은 어떻게 진행되나요?
            </div>
          </div>
        </div>
        <span className="mb-2">
          ﹒ 미리 제공되는{" "}
          <span className="underline font-semibold">커리큘럼</span>을 바탕으로
          자유롭게 예습 및 복습이 가능
        </span>{" "}
        <span className="mb-2">
          ﹒{" "}
          <span className="underline font-semibold">
            이투스 프리패스권 제공
          </span>
          으로 수업 내용에 대한 보완이 가능
        </span>{" "}
        <span>
          ﹒ 일주일에 한 번{" "}
          <span className="underline font-semibold">
            LINK TEST 진행으로 수업 이해도 확인 및 보완 강의 제공
          </span>
        </span>
      </div>
    </>
  );
}
