import Layout from "../components/layout/Layout";
import NavBar from "../components/layout/NavBar";

function Notification() {
  return (
    <Layout>
      <NavBar />
      <div>공지사항</div>
    </Layout>
  );
}

export default Notification;
