import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { isLoggedInState } from "../states/userState";

import Cookies from "js-cookie";
import Guide from "../pages/Guide";
import Info from "../pages/Info";
import Main from "../pages/Main";
import Notification from "../pages/Notification";
import Story from "../pages/Story";
const Router = () => {
  const setIsLoggedIn = useSetRecoilState(isLoggedInState);

  useEffect(() => {
    const kakaoToken = Cookies.get("kakao_token");
    const accessToken = Cookies.get("accessToken");
    const googleToken = Cookies.get("google_token");

    if (kakaoToken || accessToken || googleToken) {
      setIsLoggedIn(true);
    } else {
      localStorage.removeItem("user");
    }
  }, [setIsLoggedIn]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/info" element={<Info />} />
        <Route path="/story" element={<Story />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
