import Layout from "../components/layout/Layout";
import NavBar from "../components/layout/NavBar";

function Story() {
  return (
    <Layout>
      <NavBar />
      <div>청경 Story</div>
    </Layout>
  );
}

export default Story;
